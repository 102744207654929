import { Component, OnInit, ViewChild } from '@angular/core';
import * as $   from 'jquery' ;
import { TextAnimation } from 'ngx-teximate';
import { fadeInLeft , fadeInRight , fadeInDown , fadeInUp  } from 'ng-animate';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('enterAnimation1' , { read : '' , static : false }) enterAnimation1 : TextAnimation ;


  constructor(private router : Router) { }

  enterAnimation: TextAnimation = {
    animation: fadeInDown,
    delay: 50  ,
    type: 'word'
  };

  whiteAnimation: TextAnimation = {
    animation: fadeInLeft,
    delay: 50  ,
    type: 'letter'
  };

  yellowAnimation: TextAnimation = {
    animation: fadeInRight,
    delay: 50  ,
    type: 'letter'
  };

  darkAnimation: TextAnimation = {
    animation: fadeInUp,
    delay: 50  ,
    type: 'word'
  };


  ngOnInit() {
    $(".activeItem").removeClass('activeItem') ;
    $(".homeSidenav").addClass('activeItem') ;
    $(document).ready(function(){
      $(".lightDiv").css('background' , 'none') ;
      $(".darkText").css("visibility" , 'hidden') ;
      setTimeout(()=>{

        $(".circle11 , .circle12 , .circle13 , .circle14 ")
          .css({  "width" : 0 , "height" : 0  , "top" : "50%" , "left" : "50%" , "opacity" : "0"}) ;
        $(".circle21 , .circle22 , .circle23 , .circle24 ")
          .css({ "width" : 0 , "height" : 0  , "top" : "50%" , "left" : "50%" , "opacity" : 0 }) ;

        $(".circle3").css({"opacity" : "0"}) ;

        $(".lightCircle").css({"display" : "inline-block"}) ;

        $(".circle11 , .circle12 , .circle13 , .circle14 ").animate({
          "width" : "52px" , "height" : "52px" , 'opacity' : "1"
        } , 500 , function(){
          $(".circle11 , .circle12 , .circle13 , .circle14 ").animate({
            "width" : "26px" , "height" : "26px" , "background-color" :"#9dc4d3"
          } , 300 , function(){
            $(".circle11").animate({"top" : "-2.7%" , "left" : "47.3%"} , 200 , function(){}) ;
            $(".circle12").animate({"top" : "47.3%" , "left" : "-2.7%"} , 200 , function(){}) ;
            $(".circle13").animate({"top" : "97.3%" , "left" : "47.3%"} , 200 , function(){}) ;
            $(".circle14").animate({"top" : "47.3%" , "left" : "97.3%"} , 200 , function(){
              $(".circle11 , .circle12 , .circle13 , .circle14 ").css("color" , "#2d2d2d") ;
              $(".lightDiv").css('background' , '') ;
            }) ;
          })
        }) ;


        $(".circle21 , .circle22 , .circle23 , .circle24 ").animate({
          "width" : "40px" , "height" : "40px" , 'opacity' : "1"
        } , 500 , function(){
          $(".circle21 , .circle22 , .circle23 , .circle24 ").animate({
            "width" : "20px" , "height" : "20px"
          } , 300 , function(){
            $(".circle21").animate({"top" : "12%" , "left" : "12%"} , 200 , function(){}) ;
            $(".circle22").animate({"top" : "82%" , "left" : "12%"} , 200 , function(){}) ;
            $(".circle23").animate({"top" : "12%" , "left" : "82%"} , 200 , function(){}) ;
            $(".circle24").animate({"top" : "82%" , "left" : "82%"} , 200 , function(){
              $(".circle3").animate({"opacity" : "1"} , 500 , function(){}) ;
              $(".circle21 , .circle22 , .circle23 , .circle24 ").css({"color" : "rgb(216, 210, 254)"}) ;
            }) ;
          })
        }) ;
        setTimeout(function(){
          $(".text_div").fadeIn(0) ;
          $(".animate_all").click() ;
        } , 2000) ;

        setTimeout(()=>{


          $(".texta1  .text_bg").animate({'left' : "0%" , 'width' : "20px"} , 200 ,function(){
            $(".texta1  .text_bg").animate({ "left" : '100%' ,'width' : "0"} , 400, "swing" , function(){}) ;
          }) ;

          $(".texta1  .hellowim").animate({'width' : "20px"} , 200 ,function(){
            $(".texta1  .hellowim").animate({'width' : "100%"} , 400, "swing" , function(){}) ;
          }) ;


          $(".texta2  .text_bg").animate({'left' : "0%" , 'width' : "20px"} , 200 ,function(){
            $(".texta2  .text_bg").animate({ "left" : '100%' ,'width' : "0"} , 400, "swing" , function(){}) ;
          }) ;

          $(".texta2  .badecheabdennour").animate({'width' : "20px"} , 200 ,function(){
            $(".texta2  .badecheabdennour").animate({'width' : "100%"} , 400, "swing" , function(){}) ;
          }) ;

          $(".texta3 .text_bg").animate({'left' : "0%" , 'width' : "20px"} , 200 ,function(){

            $(".texta3 .text_bg").animate({ "left" : '100%' ,'width' : "0"} , 400, "swing" , function(){
              $(".darkText").css({'visibility' : 'visible'});
              setTimeout(()=>{
                $(".contact_me_btn , .cv_btn").css({'visibility' : 'visible'});
              } , 500) ;
            }) ;
          }) ;


          $(".texta3  .imfullstack").animate({'width' : "20px" } , 200 ,function(){
            $(".texta3  .imfullstack").animate({'width' : "100%"} , 400, "swing" , function(){}) ;
          }) ;


        } , 3000) ;
      } , 600) ;

    })
  }

  contactme() {
    $(".activeItem").removeClass('activeItem') ;
    $(".contactSidenav").addClass('activeItem') ;
    $(".loading_page_div").fadeIn(200) ;
    $(".loading_content").fadeIn(200) ;
    setTimeout(()=>{
      $(".loading_div").animate({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"} , 500 , function(){}) ;
    } , 500) ;
    setTimeout(()=>{
      this.router.navigate(['contact']) ;
    } , 1000) ;
  }

  aboutme() {
    $(".activeItem").removeClass('activeItem') ;
    $(".contactSidenav").addClass('activeItem') ;
    $(".loading_page_div").fadeIn(200) ;
    $(".loading_content").fadeIn(200) ;
    setTimeout(()=>{
      $(".loading_div").animate({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"} , 500 , function(){}) ;
    } , 500) ;
    setTimeout(()=>{
      this.router.navigate(['about']) ;
    } , 1000) ;
  }

  myskills() {
    $(".activeItem").removeClass('activeItem') ;
    $(".contactSidenav").addClass('activeItem') ;
    $(".loading_page_div").fadeIn(200) ;
    $(".loading_content").fadeIn(200) ;
    setTimeout(()=>{
      $(".loading_div").animate({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"} , 500 , function(){}) ;
    } , 500) ;
    setTimeout(()=>{
      this.router.navigate(['skills']) ;
    } , 1000) ;
  }

  myworks() {
    $(".activeItem").removeClass('activeItem') ;
    $(".contactSidenav").addClass('activeItem') ;
    $(".loading_page_div").fadeIn(200) ;
    $(".loading_content").fadeIn(200) ;
    setTimeout(()=>{
      $(".loading_div").animate({"width" : "100%" , "height" : "100%" , "border-radius" : "0%"} , 500 , function(){}) ;
    } , 500) ;
    setTimeout(()=>{
      this.router.navigate(['myWork']) ;
    } , 1000) ;
  }

  getcv() {
   // document.querySelector('.get_cv_a').click() ;
   window.open('assets/imgs/new_cv.pdf') ;
  }

  AnimationsText(table) {
    table.forEach(element => {
      element.enterPlayer.play() ;
    });

  }

}
